<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>Reports</h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12 medium-10">
        <router-link to="/reports/job-time-usage">
          <button class="button small">Job Time Usage</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportsIndex',
};
</script>
